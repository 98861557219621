import React, { useEffect } from "react";
import {  useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Footer from "Components/Footer";
import NavBar from "Components/Navbar";
import Spinner from "Components/Spinner";
import { Input, NextButton } from "./style";
import BackArrow from "Components/BackArrow";

const MakePayment = () => {
  const history = useHistory();

  const { merchant, domainBrand } = useSelector(({ merchantReducer, domainBrandReducer }) => ({
    merchant: merchantReducer,
    domainBrand: domainBrandReducer,
  }));

  // Language state
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Make Payment &mdash; Multiview MTA</title>
      </Helmet>
      <NavBar />
      <section>
        <div className="container">
          <div className="mt-5 mb-5">
            <div className="d-flex">
              <BackArrow />
              <h2 className="text-blue mt-4">
                {languageDictionaryStatus
                  ? languageDictionary["button_make_payment"]
                  : "Make Payment"}
              </h2>
            </div>
            <div className="row">
              <div className="col-12 col-xl-7 ">
                <div className="card mt-5 p-md-5">
                  <div className="card-body mb-3 p-md-3">
                    {/* --- Search box for amount to send --- */}
                    {merchant.paymentLoadingStatus === "FETCHING" ? (
                      <Spinner containerHeight="30vh" />
                    ) : (
                      <div className="row mb-5">
                        <div className="col-12">
                          <div className="mb-5 border-bottom">
                            <h5 className="text-blue">Scan the QR code below</h5>

                            <img
                              src={merchant.paymentInfo.qrCodeIamgeUrl}
                              alt="qr-code"
                              className="img-fluid"
                            />
                          </div>

                          {/* ----- Account Reference Number ----- */}
                          <div className="mb-5 text-center">
                            <p className="text-blue">Amount to pay</p>
                            <h1 className="text-success font-weight-bold">{`${merchant.paymentInfo.currency}${merchant.paymentInfo.amount}`}</h1>
                          </div>

                          <div>
                            <p className="text-blue text-capitalize">payment reference</p>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder={merchant.paymentInfo.pcn}
                              disabled
                            />
                          </div>

                          {/* ----- Proceed button ------ */}
                          <div className="mt-5 col-12">
                            <div className="row d-flex justify-content-between">
                              <div className="col-12 col-md-6 mb-3">
                                <Link
                                  to="/make-payment-otp"
                                  onClick={() => history.push()}
                                  role="button"
                                  tabIndex="-1"
                                  onKeyPress={() => {}}>
                                  <NextButton className="btn btn-lg btn-blue btn-block text-uppercase text-white">
                                    get payment
                                  </NextButton>
                                </Link>
                              </div>
                              <div className="col-12 col-md-6 mb-3">
                                <NextButton className="btn btn-lg btn-green btn-block text-uppercase text-white">
                                  share
                                </NextButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default MakePayment;
