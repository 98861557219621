import React from "react";
import NavBar from "Components/Navbar";
import Footer from "Components/Footer";

import offSetImg from "Assets/svg/transfer1.svg";
import "./index.scss";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import TransferDetails from "../TransferDetails";
import PaymentMethod from "../PaymentMethod";
import AccountToAccount from "../AccountToAccount/FindCustomer";
import CustomerAccountDetails from "../AccountToAccount/CustomerDetails";
import TransferHistory from "Pages/Dashboard/Transfer/History";
import TransactionDetails from "Pages/Dashboard/Transfer/TransactionDetails";
import cookieHelper from "../../../../Utils/Helpers/cookieHelper";
import config from "../../../../Utils/Helpers/config";

const FundsTransfer = () => {
  const { path } = useRouteMatch();
  const checkTransferModeStatus = cookieHelper.get(config.TRANSFER_MODE_STATUS);

  return (
    <div className="fdt_body">
      <NavBar />
      <main className="fdt_main">
        <div className="fdt__canvasImg">
          <img src={offSetImg} alt="off canvas icon" />
        </div>

        <section className="fdt_topSection">
          <Switch>
            {checkTransferModeStatus.transferFunds === "ENABLED" ?
              <Route exact path={path}>
                <TransferDetails />
              </Route> : ''
            }

            <Route path={`${path}/choose-payment-method`}>
              <PaymentMethod />
            </Route>

            <Route path={`${path}/history`}>
              <TransferHistory />
            </Route>

            <Route exact path={`${path}/transaction-details`}>
              <TransactionDetails />
            </Route>

            <Route path={`${path}/details`}>
              <TransactionDetails />
            </Route>

            {checkTransferModeStatus.wallet === "ENABLED" ?
              <Route path={`${path}/account-to-account`} exact>
                <AccountToAccount />
              </Route> : ''}

            <Route path={`${path}/account-to-account/details`}>
              <CustomerAccountDetails />
            </Route>
          </Switch>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default FundsTransfer;
