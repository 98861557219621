import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from "recharts";
import DatePicker from "react-datepicker";
import HeadingText from "Components/HeadingText";
import ButtonWithIcon from "Components/ButtonWithIcon";
import DropDownComponent from "Components/DropDownComponent";
import TableButton from "Components/TableRowButton";
import SubHeadingText from "Components/SubHeadingText";
import Spinner from "Components/Spinner";
import Pagination from "Components/Pagination";
import usePagination from "Components/Pagination/usePagination";
import BackArrow from "Components/BackArrow";
import ChartLegend from "./legend";
import transferIcon from "Assets/svg/transfer.svg";
import downArrow from "Assets/svg/blue-down-arrow.svg";
import downloadIcon from "Assets/svg/download.svg";
import customStyles from "Assets/styles/dropdown/customStyles";
import "./index.scss";
import {
  fetchTransferHistory,
  getTransactionDetails,
  cancelTransactionPayment
} from "Store/actions/transfer.action";
import { fetchCurrencies } from "Store/actions/bankAccount.action";
import { getAccountBalances } from "Store/actions/account.action";
import { General } from "Utils/Helpers";
import { filterData, daysFromToday } from "Utils/Helpers/general";
import navigateMethod from "Utils/Helpers/navigateMethod";
import * as Loading from "Store/constants/loadingState.constants";
import pdfGenerator from "Utils/Helpers/pdfGenerator";
import AriaModal from "react-aria-modal";

const statusStyle = {
  Completed: "tsc-text-green",
  "Waiting to pay": "tsc-text-red",
  Initiated: "tsc-text-blue",
  Cancelled: "tsc-text-red"
};

const transferStatus = {
  PENDING: ["R", "V"],
  EXPIRED: ["R", "V"],
  CONFIRMED: ["R", "V"],
  CANCELLED: ["R", "V"],
  PAID: ["R", "V"],
  PAID_OUT: ["R", "V"],
  FAILED: ["R", "V"],
  HELD: ["R", "V"],
  PENDING_PAYMENT: ["R", "P", "V", "C"]
};

const PasswordModal = ({ cancel, password, modalData }) => {
  const dispatch = useDispatch();
  const [pass, setPass] = useState("");
  const handleChange = (e) => setPass(e.target.value);

  const handleSubmit = () => {
    dispatch(
      cancelTransactionPayment({
        pcn: modalData.pcn,
        password: pass,
        payableType: modalData.payableType
      })
    );
    cancel();
  };

  const getApplicationNode = () => {
    return document.getElementById("root");
  };

  return (
    <div>
      {modalData.showModal && (
        <AriaModal
          titleText="Please input your password"
          onExit={cancel}
          getApplicationNode={getApplicationNode}
          verticallyCenter={true}
          underlayStyle={{
            backdropFilter: "blur(28px)",
            background: "none"
          }}>
          <div>
            <h3 className="passText">Please input your password</h3>
            <div className="tsh__filterPassword">
              <label htmlFor="password">
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter password"
                  autoComplete="new-password"
                  value={password}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="tsh__filterButton">
              <button onClick={cancel}>Close</button>
              <button onClick={handleSubmit} style={{ marginLeft: "10%" }}>
                Submit
              </button>
            </div>
          </div>
        </AriaModal>
      )}
    </div>
  );
};

const TableRow = ({ languageStatus, dictionary, tableData, history, triggerModal }) => {
  const {
    status,
    receiver,
    display_status,
    principalAmount,
    principalCurrencyCode,
    createdDate,
    countryTo,
    pcn,
    tax,
    payableItemId,
    payableItemType,
    transactionType
  } = tableData;

  const PaymentClick = () => {
    const paymentData = {
      // payableType: transactionType,
      payableType: payableItemType,
      payAbleId: payableItemId,
      taxAmount: parseFloat(tax),
      amount: principalAmount,
      sendAmount: principalAmount,
      pcn: pcn,
      currency: principalCurrencyCode,
      receivingCurrency: principalCurrencyCode,
      countryIsoCode3: countryTo
    };
    history.push({
      pathname: "/transfer/choose-payment-method",
      state: paymentData
    });
  };

  const cancelClick = () => {
    triggerModal({ pcn, payableItemType });
  };

  const viewClick = () => {
    const pcn = tableData["pcn"];
    const payableType = tableData["payableItemType"];
    history.push(`/transfer/details?payabletype=${payableType}?pcn=${pcn}`);
  };

  const resendClick = () => {
    history.push(`/transfer`);
  };

  const buttonMap = {
    R: (
      <TableButton
        text={languageStatus ? dictionary["SEND_AGAI_1706890304"] : "Resend"}
        key="R"
        onClick={resendClick}
      />
    ),
    V: (
      <TableButton
        text={languageStatus ? dictionary["button_view"] : "View"}
        key="V"
        onClick={viewClick}
      />
    ),
    P: (
      <TableButton
        text={languageStatus ? dictionary["button_wallet_pay"] : "Pay"}
        key="P"
        onClick={PaymentClick}
      />
    ),
    C: (
      <TableButton
        text={languageStatus ? dictionary["button_cancel"] : "Cancel"}
        key="X"
        onClick={cancelClick}
      />
    )
  };

  return (
    <>
      <tr>
        <td>
          <span>{General.formatDate(createdDate)}</span>
        </td>
        <td>
          <span>{receiver}</span>
        </td>
        <td>
          <span>{pcn}</span>
        </td>
        <td>
          <span>{`${principalCurrencyCode}${principalAmount}`}</span>
        </td>
        <td>
          <span className={statusStyle[display_status]}>{display_status}</span>
        </td>
        <td>
          <div className="tsh-row-button">
            {transferStatus[status] &&
              transferStatus[status].map((itemStatus) => buttonMap[itemStatus])}
          </div>
        </td>
      </tr>
    </>
  );
};

const generateDownloadData = (data) => {
  return data
    .reverse()
    .map(
      ({ receiver, pcn, principalCurrencyCode, principalAmount, createdDate, display_status }) => [
        receiver || "-",
        pcn || "-",
        `${principalCurrencyCode}${principalAmount}` || "-",
        General.formatDate(createdDate) || "-",
        display_status || "-"
      ]
    );
};

const TransferHistory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(daysFromToday());
  const [toDate, setToDate] = useState(new Date());
  const [filterText, setFilterText] = useState("");
  const [collection, setCollection] = useState([]);

  const [accounts, theme, transferSelector, currencies, domainBrand] = useSelector(
    ({ accountReducer, themeReducer, transferReducer, bankAccountReducer, domainBrandReducer }) => [
      accountReducer,
      themeReducer,
      transferReducer,
      bankAccountReducer["walletCurrenciesDropdown"],
      domainBrandReducer
    ]
  );

  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(
    typeof languageDictionary === "undefined" || languageDictionary === null
  );
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const { transferHistory, transferHistoryLoading, transferChart } = transferSelector;
  const reloadTransactionHandler = ({ pcn, transactionType }) => {
    const navigator = navigateMethod(history, "/transfer");
    dispatch(getTransactionDetails({ pcn, transactionType }, navigator));
  };
  const [modalData, updateModalData] = useState({
    showModal: false,
    pcn: null,
    payableType: null
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchCurrencies());
    dispatch(getAccountBalances());
  }, [dispatch]);

  useEffect(() => {
    if (currencies.length > 0) setSelectedCurrency(currencies[0]);
  }, [currencies]);

  useEffect(() => {
    dispatch(fetchTransferHistory({ currency: selectedCurrency.value, fromDate, toDate }));
  }, [dispatch, selectedCurrency, fromDate, toDate]);

  useEffect(() => {
    const data = transferHistory;
    if (data !== null) {
      const filteredData = filterData(data, filterText, ["receiver", "pcn"]);
      setCollection(filteredData);
    } else {
      setCollection([]);
    }
  }, [filterText, transferHistory]);

  const PAGE_LIMIT = 5;
  const [currentData, onPageChange] = usePagination({
    collection,
    pageLimit: PAGE_LIMIT
  });

  const showSpinner =
    accounts.loadingStatus === Loading.FETCHING || transferHistoryLoading === Loading.FETCHING;

  const hideModal = () => {
    updateModalData({
      showModal: false,
      pcn: null,
      payableType: null
    });
  };

  const triggerModal = (data) => {
    updateModalData({
      showModal: true,
      pcn: data.pcn,
      payableType: data.payableItemType
    });
  };

  return (
    <>
      <Helmet>
        <title>Transfer History</title>
      </Helmet>
      <PasswordModal cancel={() => hideModal()} modalData={modalData} />
      <div className="">
        <div className="mt-3 d-flex">
          <BackArrow />
          <HeadingText
            text={
              languageDictionaryStatus
                ? languageDictionary["WO_TRANSFER_HISTOR_57573459"]
                : "Transfer History"
            }
          />
        </div>
        {showSpinner ? (
          <Spinner />
        ) : (
          <>
            <section className="">
              <div className="tsh_chartSection">
                <div className="tsh_chart">
                  {/* <HistoryChart numberOfTransfers={transferHistory.length} /> */}
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie data={transferChart.active} dataKey="value" fill="red" label>
                        {transferChart.active.map((chartI) => (
                          <Cell fill={chartI.color} key={chartI} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                <div className="tsh_chart_legend">
                  <ChartLegend data={transferChart.all} />

                  <div className="row mt-5">
                    <div className="col-12 col-md-6 mb-3">
                      <ButtonWithIcon
                        buttonIcon={transferIcon}
                        buttonText={
                          languageDictionaryStatus
                            ? languageDictionary["WO_TRANSFER_FUND_-1694451190"]
                            : "Transfer Funds"
                        }
                        clickAction={() => history.push("/transfer")}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <ButtonWithIcon
                        buttonIcon={transferIcon}
                        buttonText={
                          languageDictionaryStatus
                            ? languageDictionary["WO_VIEW_BENEFICIARIE_-45467962"]
                            : "View Beneficiaries"
                        }
                        clickAction={() => history.push("/beneficiaries")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="tsh_bottomSection">
              <div className="row d-flex justify-content-between">
                <div className="col-xl-9">
                  <div className="row">
                    <div className="col-12 col-md-4 mb-2">
                      <SubHeadingText
                        text={
                          languageDictionaryStatus
                            ? languageDictionary["WO_SELECT_CURRENC_1917455719"]
                            : "Select Currency"
                        }
                      />
                      <Select
                        options={currencies}
                        styles={customStyles}
                        isClearable={false}
                        isSearchable={false}
                        value={selectedCurrency}
                        customTheme={theme}
                        onChange={(option) => {
                          setSelectedCurrency(option);
                        }}
                        components={{
                          IndicatorSeparator: null,
                          DropdownIndicator: () => <DropDownComponent iconSource={downArrow} />
                        }}
                      />
                    </div>

                    <div className="col-6 col-md-2">
                      <SubHeadingText
                        text={
                          languageDictionaryStatus
                            ? languageDictionary["para_start_date_"]
                            : "Start Date"
                        }
                      />
                      <DatePicker
                        selected={fromDate}
                        onChange={setFromDate}
                        startDate={fromDate}
                        endDate={toDate}
                        maxDate={toDate}
                        dateFormat="dd MMMM yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selectsStart
                        className="tsh_datePicker"
                      />
                    </div>
                    <div className="col-6 col-md-2">
                      <SubHeadingText
                        text={
                          languageDictionaryStatus ? languageDictionary["l_end_date"] : "End Date"
                        }
                      />
                      <DatePicker
                        selected={toDate}
                        onChange={setToDate}
                        startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        dateFormat="dd MMMM yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selectsEnd
                        className="tsh_datePicker"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-3">
                  <div className="row d-flex justify-content-between">
                    <div className="col-8">
                      <div className="tsh__filterInput">
                        <label htmlFor="filterText">
                          <input
                            type="text"
                            name="filterText"
                            id="filterText"
                            autoComplete="new-password"
                            placeholder={
                              languageDictionaryStatus ? languageDictionary["b_search"] : "search"
                            }
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-4">
                      <button
                        className="tsh_controls__download"
                        onClick={() =>
                          pdfGenerator({
                            heading: "Transfer History",
                            tableHeaders: ["Beneficiaries", "PCN", "Amount", "Date", "Status"],
                            data: generateDownloadData(transferHistory),
                            isAuto: false
                          })
                        }>
                        <img src={downloadIcon} alt="download icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tsh_table">
                <table>
                  <thead>
                    <tr>
                      <th scope="col">
                        {languageDictionaryStatus ? languageDictionary["l_date"] : "Date"}
                      </th>
                      <th scope="col">
                        {languageDictionaryStatus
                          ? languageDictionary["para_beneficiary"]
                          : "Beneficiary"}
                      </th>
                      <th scope="col">
                        {languageDictionaryStatus
                          ? languageDictionary["WO_TRANSACTION_I_-307229512"]
                          : "Transaction ID"}
                      </th>
                      <th scope="col">
                        {languageDictionaryStatus ? languageDictionary["para_amount"] : "Amount"}
                      </th>

                      <th scope="col">
                        {languageDictionaryStatus ? languageDictionary["l_status"] : "Status"}
                      </th>
                      <th scope="col">
                        {languageDictionaryStatus ? languageDictionary["l_action"] : "Action"}
                      </th>
                      {/* <th /> */}
                    </tr>
                  </thead>
                  <tbody>
                    {currentData < 1 ? (
                      <>
                        <tr>
                          <td colSpan="6" className="text-center bg-grey">
                            <span>No transfer history available</span>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {currentData.map((iData) => (
                          <TableRow
                            languageStatus={languageDictionaryStatus}
                            dictionary={languageDictionary}
                            tableData={iData}
                            key={iData["payableItemId"]}
                            history={history}
                            reloadClick={reloadTransactionHandler}
                            triggerModal={triggerModal}
                          />
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination
                resultName="Total Transaction Records"
                onPageChange={onPageChange}
                pageLimit={PAGE_LIMIT}
                totalRecords={collection.length}
              />
            </section>
          </>
        )}
      </div>
    </>
  );
};

export default TransferHistory;
