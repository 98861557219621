/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import NavBar from "Components/Navbar";
import Footer from "Components/Footer";
import styles from "./index.module.scss";
import * as Loading from "Store/constants/loadingState.constants";
import { transactionTypesMap } from "Utils/Helpers/transactionTypesMap";
import fundsIcon from "Assets/svg/funds.svg";
import { getAllComplianceForm } from "Store/actions/compliance.action";
import DashboardCard from "Components/DashboardCard";
import Axios from "Utils/Services/Axios.service";
import cookieHelper from "../../../Utils/Helpers/cookieHelper";
import config from "../../../Utils/Helpers/config";
import surfaceIcon from "Assets/svg/surface1.svg";
import Loader from "../../../Components/loader/Loader";

const DashboardHome = () => {
  const dispatch = useDispatch();
  const [dataTransTypes, setDataTransTypes] = useState([]);
  const { authSelector, profile, compliance, complianceAction, domainBrand } = useSelector(
    ({ authReducer, profileReducer, complianceReducer, domainBrandReducer }) => ({
      authSelector: authReducer,
      profile: profileReducer,
      complianceAction: complianceReducer.complianceFormList,
      compliance: complianceReducer,
      domainBrand: domainBrandReducer
    })
  );

  const userData = profile.userData;

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      const url = `/web-services/api/v6/services/transactionmanagement/transaction-types`;
      const { data: { data } } = await Axios({ url, method: "GET" });
      let transferRouteObject;
      let newTransTypes = ["ADD_NEW_USER", "REQUEST_BANK_ACCOUNT", "TRANSFER_MODES"];
      data["transactionTypes"].forEach(element => newTransTypes.push(element["serviceType"]));
      if (!newTransTypes.includes("ACCOUNTPAYMENT")) {
        newTransTypes = newTransTypes.filter(e => e !== "TRANSFER_MODES");
        transferRouteObject = { transferFunds: "DISABLED", wallet: "ENABLED" };
      }
      if (!newTransTypes.includes("MULTICURRENCY_WALLET_VIRTUAL_ACCOUNT_MANAGEMENT")) {
        newTransTypes = newTransTypes.filter(e => e !== "TRANSFER_MODES");
        newTransTypes = newTransTypes.filter(e => e !== "REQUEST_BANK_ACCOUNT");
        transferRouteObject = { transferFunds: "ENABLED", wallet: "DISABLED" };
      }
      if (newTransTypes.includes("ACCOUNTPAYMENT") && newTransTypes.includes("MULTICURRENCY_WALLET_VIRTUAL_ACCOUNT_MANAGEMENT")) {
        newTransTypes = newTransTypes.filter(e => e !== "ACCOUNTPAYMENT");
        transferRouteObject = { transferFunds: "ENABLED", wallet: "ENABLED" };
      }
      const merchantPaymentStatus = newTransTypes.indexOf("MERCHANTPAYMENTS") > -1;
      const merchantCookieData = merchantPaymentStatus ? "ENABLED" : "DISABLED";
      cookieHelper.set(config.TRANSFER_MODE_STATUS, transferRouteObject, 1);
      cookieHelper.set(config.MERCHANT_PAYMENT_STATUS, merchantCookieData, 1);
      setDataTransTypes(newTransTypes);
    };
    if (profile.userProfileLoading === Loading.SUCCESS) fetchData().catch(err => console.log(err));
    if (compliance.loading !== Loading.SUCCESS || compliance.loading === Loading.ERROR) dispatch(getAllComplianceForm());
  }, [profile.userProfileLoading]);

  const complianceFormLoading = compliance.loading === Loading.SUCCESS;
  const domainBrandLoading = domainBrand.loading === Loading.SUCCESS;
  const languageDictionary = domainBrand.domainBrand["languageDictionary"];
  const languageDictionaryStatus = !(typeof languageDictionary === "undefined" || languageDictionary === null || languageDictionary === "");
  const visibleCardsCount = dataTransTypes.filter(forEach => transactionTypesMap[forEach].visibleTo.includes(userData.customerType)).length;

  return (
    <>
      <Helmet>
        <title>
          {languageDictionaryStatus ? languageDictionary["para_dashboard"] : "Dashboard"}
        </title>
      </Helmet>
      <div className={styles.dashboard}>
        <NavBar />

        <div className={styles.dashboard__main}>
          <div className={styles.canvasOffset} />
          <div className={styles.canvasImg}>
            <img src={fundsIcon} alt="" />
          </div>
          {domainBrandLoading ?
            <main className={styles.mainSection}>
              <div className={styles.mainSection__heading}>
                <p>
                  {languageDictionaryStatus ? languageDictionary["WO_HELL_589729818"] : "Hello"}{" "}
                  {userData.firstName && `${userData.firstName},`}
                </p>
                <p>
                  {languageDictionaryStatus ? languageDictionary["WO_WHAT_WOULD_YOU_LIKE_-1384037072"] : "What would you like to do today?"}
                </p>
              </div>

              <section className="mt-5">
                <div className="row g-0">
                  {dataTransTypes.map((transType) => {
                    let type = transactionTypesMap[transType];
                    if (typeof type === "undefined") type = {
                      icon: surfaceIcon,
                      text: transType,
                      to: "/",
                      visibleTo: ["ADMIN"]
                    };
                    const userType = (userData.customerType === null) ? userData["userType"] : userData.customerType;
                    const show = type?.visibleTo.includes(userType);
                    return (type.to && show && (
                      <DashboardCard key={type.id} to={type.to} icon={type.icon} text={type.text}
                                     language={languageDictionary} languageKey={type.lingualText}
                                     languageDictionaryStatus={languageDictionaryStatus} count={visibleCardsCount} />
                    ));
                  })}
                </div>
              </section>
            </main> : <Loader />
          }
        </div>
        <Footer />
      </div>
    </>
  );
};

export default DashboardHome;
