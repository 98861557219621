import React from "react";
import { useHistory } from "react-router-dom";
import rightArrowIcon from "Assets/svg/right-arrow.svg";
import styled from "@emotion/styled";

const GreenShape = styled.div`
  position: absolute;
  background-color: #0FCC98;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  top: -51px;
  left: -60px;
`;

const BlueShape = styled.div`
  position: absolute;
  background-color: #3796f6;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  right: -35px;
  bottom: -30px;
`;

const DashboardCard = ({ to, icon, text, language, languageKey, languageDictionaryStatus, count }) => {

  const history = useHistory();
  const cardStyle = (count % 2 === 0 && count < 6) ? 'col-xl-6' : 'col-xl-4';

  return (
    <div className={'col-md-6 mb-3 ' + cardStyle}>
      <div className="card card-color w-100 rounded-2 p-3 position-relative overflow-hidden"
           onClick={() => history.push(`${to}`, { text: text })}
           role="button"
           tabIndex="-1"
           onKeyPress={() => {}}
      >
        <div className="card-body pt-2">
          <div className="row g-0 align-items-center">
            <div className="col">
              <div className="media align-items-center">
                <img src={icon} alt="account icon" className="mr-3 img-fluid" />
                <div className="media-body">
                  <h6 className="mt-2 primary-size">{languageDictionaryStatus ? language[languageKey] : text}</h6>
                </div>
              </div>
            </div>
            <div className="col-auto text-center pl-2">
              <img src={rightArrowIcon} alt="right arrow icon" />
            </div>
          </div>
        </div>
        <GreenShape />
        <BlueShape />
      </div>
    </div>
  );
};

export default DashboardCard;
